
    import {getPublicPath, register} from '/usr/local/data/service/src/node/build/react/3.x/entrypoints/shared.js'
    const requireEntry = () => require('/cache/build/build_vtex_master/vtex.react-vtexid@4.67.0/src/react/validations.js')

    __webpack_public_path__ = getPublicPath('vtex.react-vtexid@4.67.0')

    const {renderHotReload, setupHMR} = register(requireEntry, module, 'vtex.react-vtexid@4.67.0', 'vtex.react-vtexid@4.x', 'validations')
    if (module.hot && setupHMR) {
      require('/usr/local/data/service/node_modules/webpack/hot/dev-server.js')
      const hotLog = require('/usr/local/data/service/node_modules/webpack/hot/log.js')
      const hotEmitter = require('/usr/local/data/service/node_modules/webpack/hot/emitter.js')

      setupHMR(hotEmitter, hotLog)
      if (renderHotReload) {
        module.hot.accept('/cache/build/build_vtex_master/vtex.react-vtexid@4.67.0/src/react/validations.js', renderHotReload)
      }
    }
